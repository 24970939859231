@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}

p {
  font-size: 1.125rem;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(207, 207, 207); 
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: transparent;
  border-radius: .3rem
}

.desc {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page {
  margin-top: 5rem;
  padding-bottom: 5rem;
}
.profile {
  border-radius: 2rem;
  max-width: 40%;
  max-height: 40%;
  min-width: 40%;
  min-height: 40%;
  margin-bottom: 5rem;
  -webkit-filter: grayscale(40%);
  filter: grayscale(40%);
}

.desc {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page {
  margin-top: 5rem;
  padding-bottom: 5rem;
}
.page {
  margin-top: 5rem;
  padding-bottom: 5rem;
}

.workCardRow {
  margin-bottom: 1rem;
}


.buttonRead, .buttonRead:hover, .buttonRead:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #000000 !important;
  box-shadow: none !important;
  outline: none !important;
}

.buttonRead:active {
  color: #ffffff !important;
}

.workCardTitle {
  font-size: 120%;
  font-weight: bold;
  margin-bottom: 1rem;
}

.workCardSubtitle {
  margin-bottom: 1rem;
}

.workCardHidden {
  border: none;
}
.blogCardRow {
  margin-bottom: 1rem;
}

.page {
  margin-top: 5rem;
  padding-bottom: 5rem;
}

.buttonRead, .buttonRead:hover, .buttonRead:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #000000 !important;
  box-shadow: none !important;
  outline: none !important;
}

.buttonRead:active {
  color: #ffffff !important;
}

.blogCardTitle {
  font-size: 120%;
  font-weight: bold;
  margin-bottom: 1rem;
}

.blogCardSubtitle {
  margin-bottom: 1rem;
}

.blogCardHidden {
  border: none;
}
.desc {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page {
  margin-top: 5rem;
  padding-bottom: 5rem;
}
.desc {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page-blog {
  text-align: left;
  margin-top: 5rem;
  padding-bottom: 5rem;
}

img {
  max-width: 90%; 
  max-height: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.blogShareButton {
  cursor: pointer;
}
.desc {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page-work {
  text-align: left;
  margin-top: 5rem;
  padding-bottom: 5rem;
}

img {
  max-width: 90%; 
  max-height: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
