.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: transparent;
  border-radius: .3rem
}

.desc {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page {
  margin-top: 5rem;
  padding-bottom: 5rem;
}