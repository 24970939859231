.desc {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page-blog {
  text-align: left;
  margin-top: 5rem;
  padding-bottom: 5rem;
}

img {
  max-width: 90%; 
  max-height: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.blogShareButton {
  cursor: pointer;
}