@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

.App {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}

p {
  font-size: 1.125rem;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(207, 207, 207); 
}