.profile {
  border-radius: 2rem;
  max-width: 40%;
  max-height: 40%;
  min-width: 40%;
  min-height: 40%;
  margin-bottom: 5rem;
  -webkit-filter: grayscale(40%);
  filter: grayscale(40%);
}

.desc {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page {
  margin-top: 5rem;
  padding-bottom: 5rem;
}