.blogCardRow {
  margin-bottom: 1rem;
}

.page {
  margin-top: 5rem;
  padding-bottom: 5rem;
}

.buttonRead, .buttonRead:hover, .buttonRead:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #000000 !important;
  box-shadow: none !important;
  outline: none !important;
}

.buttonRead:active {
  color: #ffffff !important;
}

.blogCardTitle {
  font-size: 120%;
  font-weight: bold;
  margin-bottom: 1rem;
}

.blogCardSubtitle {
  margin-bottom: 1rem;
}

.blogCardHidden {
  border: none;
}